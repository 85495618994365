<template>
  <div>
    <h3 class="monitorH hide">Monitor</h3>
    <el-scrollbar style="height:100%">
      <div class="slotContent">

        <div
          class="row"
          style="overflow:hidden;">

          <div
            class="col-lg-2 col-md-3 col-sm-4 col-xs-6 col-xs-12"
            v-for="(item,index) in totalSlotNumber"
            :key="item">
            <div
              :id="'slot' + (index +1)"
              class="graph graph2">
              <div class="caption">
                <label class="left slotNumber">{{(index + 1) + "."}}</label>
                <input
                  class="checkboxStyle checkboxStyle2 left"
                  :disabled='!currentRObj[index]'
                  type="checkbox"
                  :id="'slotInput' + (index +1)"
                  :checked="currentRObj[index]?currentRObj[index].IsEnabled:true" />
                <label
                  class="inputLabel"
                  :for="'slotInput' + (index +1)"
                  @click="setAdapterOperation((index+1),currentRObj[index]?currentRObj[index].IsEnabled:'')"></label>
                <span
                  class="ip-addr left disconnected i18n ellipsis"
                  name="i18n_disconnected"
                  v-if="currentRObj[index]"
                  :title="currentRObj[index].IpStr | enhanceLocalIp(currentRObj[index].LocalIpStr)"
                  :class="currentRObj[index] | setSlotClass">
                  {{
                    currentRObj[index].SignalType == '5G'? `5G${[index].CarrierName? '-' + currentRObj[index].CarrierName: ''}`
                    : currentRObj[index].CarrierName? currentRObj[index].CarrierName : currentRObj[index].IpStr
                  }}
                </span>
                <i
                  class="resetIcon right i18n iconfont"
                  name="i18n_reset"
                  :disabled="resetConfig(currentRObj[index])"
                  title="Reset"
                  @click="setReset(index+1)"></i>
                <i
                  href="#"
                  @click="showConfig(index,$event)"
                  class="iconfont editIcon right"
                  :disabled="editIconConfig(currentRObj[index])"></i>
                <i
                  class="name right  ellipsis iconfont"
                  :class="currentRObj[index]|showTypeIcon"></i>
              </div>
              <quality-chart
                width="100%"
                :ref="`qualityChart${index}`"
                :boxId="`quality${index}`"
                :qualityVal="qualityValList[index]"
                :yAxis="yAxis" />
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <div class="btn-group f_right adapter-group dropup">
      <quality-list
        :selectTotal="selectTotal"
        type="medium"
        @changeQuality="changeAdapterQuality"/>
    </div>
    <div
      class="config-box"
      :class="{hide:showConfigT}"
      @click="closePage">
      <div class="config-header">
        <div class="text_left">Config
          <i class="iconfont configT-icon f_right"></i>
        </div>
      </div>
      <iframe
        :src="configTUrl"
        frameborder="0"
        style="width:100%;height:100%;"></iframe>
    </div>
  </div>
</template>
<script type="text/javascript">
import Bus from '@/assets/js/vueBus.js'
import { mapState } from 'vuex'
import Outil from '@/assets/js/utils'
import { adAnalytice } from '@/assets/js/googleAnalyze/advanceControl'
import { Receiver, receviceParams } from '@/assets/api/common/receiver.js'
import qualityChart from '@/components/qualityChart'
import qualityList from '@/components/qualityList'
export default {
  name: 'AdapterList',
  components: {
    qualityChart,
    qualityList
  },
  data () {
    return {
      totalSlotNumber: [],
      qualityText: '6Mb/s',
      charts: {},
      value: true,
      showConfigT: true,
      lastTime: 0,
      currentTime: 0,
      configTUrl: '',
      currentRObj: [],
      flowQuality: {},
      qualityArray: ['1.2Mb/s', '2.4Mb/s', '6Mb/s', '12Mb/s'],
      qualityTotalPoints: 300,
      totalPoints: 300,
      qualityData: [],
      qualitySlot: [],
      qualityPlot: null,
      selectTotal: '1.2Mb/s',
      options: {
        chart: {
          type: 'area',
          marginLeft: 38,
          marginRight: 1,
          marginBottom: 28,
          backgroundColor: '',
          animation: false
        },
        colors: ['Lime'],
        title: {
          text: ''
        },
        credits: {
          enabled: false // 版权信息
        },
        legend: {
          enabled: false // 图例
        },
        xAxis: {
          endOnTick: true,
          startOnTick: true,
          allowDecimals: false,
          gridLineWidth: 1, // x网格线宽度
          tickWidth: 2,
          tickLength: 1,
          lineColor: '#555',
          gridLineColor: '#555',
          tickColor: '#555',
          max: 10, // x最大值
          min: 0,
          tickPositions: [0, 60, 120, 180, 240, 300],
          labels: { // 轴坐标和单位
            formatter: function () {
              return `${this.value / 60}:00` // 格式化x坐标显示
            },
            style: {
              color: '#999'
            }
          }
        },
        yAxis: {
          title: {
            text: ''
          },
          gridLineWidth: 1, // x网格线宽度
          tickInterval: 1500,
          gridLineColor: '#555',
          lineColor: '#555',
          tickWidth: 0,
          tickLength: 0,
          tickColor: '#555',
          max: 6000,
          labels: {
            formatter: function () {
              return this.value / 1000
            },
            style: {
              color: '#999'
            }
          },
          x: -5 // 设置偏移y轴坐标
        },
        tooltip: {
          formatter: function () {
            return this.y + 'kbps'
          }
        },
        plotOptions: {
          area: {
            lineWidth: 0,
            marker: { // 鼠标移入选中的点
              enabled: false,
              symbol: 'circle',
              radius: 0,
              states: {
                hover: {
                  radius: 2,
                  fillColor: 'red',
                  lineColor: '',
                  lineWidth: 1,
                  border: 1
                }
              }
            }
          }
        },
        series: [{
          name: '',
          data: [],
          states: {
            hover: {
              enabled: false
            }
          }
        }]
      },
      chart: [],
      optionData: [],
      qualityValList: [],
      yAxis: { // y轴坐标
        tickAmount: 5, // 默认显示多少个刻度
        max: 6000// 默认显示的最大值
      }
    }
  },
  computed: {
    ...mapState({
      ReceiverInfoObj: state => state.ReceiverInfoObj,
      State: state => state,
      GetKernelStateObj: state => state.GetKernelStateObj
    })
  },
  created () {
    var _self = this
    Bus.$on('getAdapter', function (data) {
      _self.getFlowAndSaveInfo(data)
    })
    Bus.$on('selectOtherR', function () {
      _self.createDefault10Quality()
    })
    Bus.$on('clearAdapter', () => {
      _self.currentRObj = []
    })
  },
  watch: {
    'GetKernelStateObj.CurrentTStrIdHex': function () {
      setTimeout(() => {
        for (var i = 0; i < this.totalSlotNumber.length; i++) {
          let id = `qualityChart${i}`
          if (this.$refs[id][0]) {
            this.$refs[id][0].createQuality()
          }
        }
      }, 3000)
    },
    'GetKernelStateObj.WorkMode': function () {
      if (this.GetKernelStateObj.WorkMode == '0') {
        setTimeout(() => {
          for (var i = 0; i < this.totalSlotNumber.length; i++) {
            let id = `qualityChart${i}`
            if (this.$refs[id][0]) {
              this.$refs[id][0].createQuality()
            }
          }
        }, 3000)
      }
    }
  },
  mounted: function () {
    this.createDefault10Quality()
  },
  methods: {
    enhanceLocalIp (IpStr, LocalIpStr) {
      IpStr = IpStr || ''
      LocalIpStr = LocalIpStr || ''
      const result = LocalIpStr ? `${IpStr} &#8594 ${LocalIpStr}` : IpStr
      return he.decode(result)
    },
    // 由于选择的R可能连接不上cc所以添加一个默认创建10个的function
    createDefault10Quality (btn) {
      this.totalSlotNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      // 初始化300个点数据
      this.options.series[0].data = []
      this.optionData = []
      for (var i = 0; i < this.qualityTotalPoints; i++) {
        this.options.series[0].data.push({ y: null, x: i })
      }
    },
    // 获取流量图的个数及相关信息
    getFlowAndSaveInfo (data) {
      const _self = this
      if (data.errorInfo != 'Module Not Found.') {
        var adapterList = data.AdapterModels
        var array = []; var flowObjArray = []
        // 这个判断是为了让只有slot的数量改变时采取重新生成
        if (_self.totalSlotNumber.length != adapterList.length) {
          _self.totalSlotNumber = []
          for (var i = 0; i < adapterList.length; i++) {
            array.push(i)
            flowObjArray.push(adapterList[i])
            this.flowQuality[i] = []
          }
          _self.totalSlotNumber = array
          setTimeout(function () {
            // 为了旧的用的
            _self.qualityData = []
            for (var i = 0; i < _self.qualityTotalPoints; i++) {
              _self.qualityData.push([i, 0])
            }
          }, 80)
        } else {
          for (let j = 0; j < adapterList.length; j++) {
            // 获取显示数据，并更新图表
            let kbps = (adapterList[j].Speed * 8) / 1024
            this.qualityValList[j] = kbps
            this.$set(this.qualityValList, j, kbps)

            flowObjArray.push(adapterList[j])
          }
        }
        _self.currentRObj = flowObjArray
      }
    },
    // 更改流量显示范围
    changeAdapterQuality (obj) {
      // 埋点统计
      adAnalytice('adLive', obj.data)

      // 更新流量图坐标
      this.selectTotal = obj.data
      let index = this.selectTotal.indexOf('M')
      let maxValue = Number(this.selectTotal.substring(0, index)) * 1000

      this.yAxis.max = maxValue
      this.qualityText = obj.data
    },
    // send勾选流量图的index
    setAdapterOperation (index, haveChecked) {
      if (!this.currentRObj[index]) return
      // 埋点统计
      if (haveChecked) {
        adAnalytice('adLive', 'FlowClose')
      } else {
        adAnalytice('adLive', 'FlowOpen')
      }

      haveChecked ? haveChecked = false : haveChecked = true
      let data = Object.assign({ rid: this.State.selectRId.toLowerCase(), Data: '{"Index":' + index + ',"IsEnabled":' + haveChecked + '}' }, receviceParams.setSlot.setSlotIndex)
      this.axios.post(Receiver().setSlot.setSlotIndex, data).then(res => {}).catch(() => {})
    },
    setReset (index) {
      // 埋点统计
      adAnalytice('adLiveReset')
      let data = Object.assign({ rid: this.State.selectRId.toLowerCase(), Data: '{"Index":' + index + '}' }, receviceParams.setSlot.setSlotReset)
      this.axios.post(Receiver().setSlot.setSlotReset, data).then(res => {}).catch(() => {})
    },
    // 获取config_t的地址
    showConfig (index, event) {
      // 埋点统计
      if (this.GetKernelStateObj.CurrentTType >= 10 && this.GetKernelStateObj.CurrentTType <= 14) return
      adAnalytice('adLiveConfigT')
      if ($(event.target).attr('disabled') || !this.$store.state.selectSourceId) return
      if (this.State.selectSourceStatus == '0') {
        this.$message({
          type: 'warning',
          message: this.$t('lang.DeviceOffLine')
        })
        return
      }
      this.lastTime = this.currentTime
      this.currentTime = new Date().getTime()
      if (this.currentTime - this.lastTime < 2000) return
      index++
      const strWindowFeatures = 'height=600, width=1027, top=200, left=300, toolbar=no, menubar=no, titlebar=no, scrollbars=no, resizable=no,channelmode=no, location=no, status=no'
      window.open(
        `http://${this.$store.state.common.baseUrl.switchUrl}/configT.html#?tsession=${localStorage.getItem('NewCCsession')}&peerid=${Outil.encryption(this.$store.state.selectSourceId)}`,
        '_blank',
        strWindowFeatures
      )
    },
    closePage () {
      this.showConfigT = true
      this.$store.state.showBg = true
    },
    returnContent (data, index) {
      if (!data) return
      if (data.CarrierName) {
        return data.CarrierName
      } else {
        return data.IpStr
      }
    },
    resetConfig (options) {
      if (!options) return true
      if (options.IsUsing) {
        return false
      } else {
        return true
      }
    },
    editIconConfig (options) {
      if (this.GetKernelStateObj.CurrentTType >= 10 && this.GetKernelStateObj.CurrentTType <= 14) return true
      if (!options) return true
      if (options.EnableConfigT) {
        return false
      } else {
        return true
      }
    },
    /* 判断是否是内网IP */
    isInnerIPFn () {
      // 获取当前页面url
      var curPageUrl = window.location.href

      var reg1 = /(http|ftp|https|www):\/\//g// 去掉前缀
      curPageUrl = curPageUrl.replace(reg1, '')

      var reg2 = /\:+/g// 替换冒号为一点
      curPageUrl = curPageUrl.replace(reg2, '.')

      curPageUrl = curPageUrl.split('.')// 通过一点来划分数组

      var ipAddress = curPageUrl[0] + '.' + curPageUrl[1] + '.' + curPageUrl[2] + '.' + curPageUrl[3]

      var isInnerIp = false// 默认给定IP不是内网IP
      var ipNum = this.getIpNum(ipAddress)
      /**
       * 私有IP：A类  10.0.0.0    -10.255.255.255
       *       B类  172.16.0.0  -172.31.255.255
       *       C类  192.168.0.0 -192.168.255.255
       *       D类   127.0.0.0   -127.255.255.255(环回地址)
       *
       */
      var aBegin = this.getIpNum('10.0.0.0')
      var aEnd = this.getIpNum('10.255.255.255')
      var bBegin = this.getIpNum('172.16.0.0')
      var bEnd = this.getIpNum('172.31.255.255')
      var cBegin = this.getIpNum('192.168.0.0')
      var cEnd = this.getIpNum('192.168.255.255')
      var dBegin = this.getIpNum('127.0.0.0')
      var dEnd = this.getIpNum('127.255.255.255')
      isInnerIp = this.isInner(ipNum, aBegin, aEnd) || this.isInner(ipNum, bBegin, bEnd) || this.isInner(ipNum, cBegin, cEnd) || this.isInner(ipNum, dBegin, dEnd)
      console.log('是否是内网:' + isInnerIp)
      return isInnerIp
    },
    getIpNum (ipAddress) { /* 获取IP数 */
      var ip = ipAddress.split('.')
      var a = parseInt(ip[0])
      var b = parseInt(ip[1])
      var c = parseInt(ip[2])
      var d = parseInt(ip[3])
      var ipNum = a * 256 * 256 * 256 + b * 256 * 256 + c * 256 + d
      return ipNum
    },
    isInner (userIp, begin, end) {
      return (userIp >= begin) && (userIp <= end)
    }
  },
  filters: {
    setSlotClass: function (value) {
      if (!value) return
      let isResetting = value.IsResetting
      let slotStatus = value.Status
      if (isResetting && slotStatus != '0') {
        return 'dialing'
      } else {
        switch (slotStatus) {
          case 0:
            return 'disconnected'
          case 1:
            return 'dialing'
          case 2:
            return 'connecting'
          case 3:
            return 'connected'
        }
      }
    },
    showTypeIcon: function (options) {
      if (!options) return
      const type = options.SignalType
      switch (type) {
        case 'wifi':
          return 'wifiIcon'
        case 'eth':
          return 'ethIcon'
        case '3G':
          return 'cellularIcon'
        case '4G':
          return 'cellularIcon'
        default:
          return 'cellularIcon'
      }
    }
  }
}
</script>
<style type="text/css" scoped>
.container-fluid {
  padding-right: 15px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.adapter-group {
  margin-right: 25px;
}
.adapter-group ul {
  min-width: 100px;
}
.adapterbtn {
  background: #444;
  color: #fff;
}
.adapter-group .checkBox {
  margin-left: 5px;
}
input[type='checkbox'],
input[type='radio'] {
  visibility: hidden;
}
input[type='checkbox']:disabled + label {
  background: #999;
}
i[disabled] {
  color: #999;
}
.dropdown-menu > li > a {
  color: #fff;
  display: inline-block;
  padding: 3px 5px;
}
.dropdown-menu > li:hover {
  background-color: rgba(57, 170, 80, 0.25);
  color: #fff;
}
.btn.focus,
.btn:focus,
.btn:hover {
  color: #fff;
}
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 10px;
}
/* @media (min-width: 1760px) and (max-width:1980px) {
        .slotContent .col-lg-2 {
            width: 19.5%;
        }
    } */
@media (min-width: 993px) and (max-width: 1760px) {
  .slotContent .col-md-3 {
    width: 25%;
  }
}
.slotContent {
  max-height: 375px;
}
.singal {
  top: -1px;
}

/* ashok添加自适应代码 */
@media screen and (min-width: 1600px) and (max-width: 1919px) {
  .slotContent .col-lg-2 {
    width: 24.5%;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1599px) {
  .slotContent .col-md-3 {
    width: 33%;
  }
}

@media screen and (min-width: 760px) and (max-width: 1279px) {
  .slotContent .col-md-3 {
    width: 48%;
  }
}

@media screen and (min-width: 500px) and (max-width: 759px) {
  .slotContent .col-md-3 {
    width: 50%;
  }
  .caption span {
    width: 43%;
    font-size: 12px;
  }
}
</style>
